import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És un petit bolet amb un capell d’1 a 3,5 cm de diàmetre, aviat pla o amb un petit mamelló i després fins i tot umbilicat amb el marge estès. El capell presenta una coloració canviant, al principi gris negrós que empal·lideix a crema brut, principalment cap al marge. Les làmines de davall són d’adnades o decurrents amb l’edat i bifurcades, de color que va del blanquinós a un poc rogenc. El peu és corbat i més ample a la part superior del mateix color que el capell o un poc més negre verdós a la part inferior. Les espores són blanques en massa, un poc fusiformes, de (10) 11-13 (14) x (4,5) 5-5,5 (6,5) micres, amb mides més grans les provinents de basidis bispòrics.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      